<template>
	<main>
		<h1>Classes</h1>
		<ResponsiveTable :headers="['Name', 'Code', '']" :rows="classes" />

		<BaseButton type="submit" :href="{name: 'AdminClassesCreate'}">
			Create class
		</BaseButton>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import BaseButton from '../../components/BaseButton';
	import ResponsiveTable from '../../components/ResponsiveTable';
	import {hasRole, isAdmin, roleGuard} from '../../inc/auth';

	export default {
		...roleGuard('Courses', 'any', 'teacher'),
		components: {
			BaseButton,
			ResponsiveTable
		},
		data() {
			return {
				classes: []
			};
		},
		created() {
			Backend.get('classes').then(res => {
				this.classes = res.data.map(c => {
					let actions = {label: ''};

					if(isAdmin() || hasRole('Classes', c.id, 'teacher')) {
						actions = [{label: 'Edit', href: {name: 'AdminClassesEdit', params: {id: c.id}}}];

						if(isAdmin()) {
							actions.unshift({label: 'Students', href: {name: 'AdminUsers', query: {class: c.id}}});
						}
					}

					return [
						{label: c.name},
						{label: c.code},
						actions
					];
				});
			});
		}
	};
</script>